import React from "react";
import {Link} from "react-router-dom";
import '../../dropdown.687117f.css';
import '../../1componentLibraryTheme2021.aeafbeb.css';
import '../../detail_section_form.86751c8.css';
import '../../detail_sections.d2636fb.css';
import '../../1banner.57ada48.css';
import '../../LivesportFinderLatin.06df268.css';
import '../../core.18d0bbe.css';
import '../../variables.1611a44.css';
import '../../themes.17e5560.css';
import '../../common.ad425e7.css';
import '../../components_shared.28090ff.css';
import '../../cookie.0635bcc.css';
import '../../multiLang.01f626d.css';
import '../../core_common.7c5dcf1.css';
import '../../lsid.0ffe621.css'; 
import '../../dropdown.687117f.css';
import '../../componentLibraryTheme2021.aeafbeb.css';
import '../../live_header.067a8ee.css';
import '../../live_sidemenu.4e809a9.css';
import '../../live_sections.5c86d21.css';
import '../../wizard.561951a.css';
import '../../league_onboarding.b59db54.css';
import '../../live_footer.26a4abe.css';
import '../../tabs_filters.0656451.css';
import '../../live_tabs.be1971d.css';
import '../../headline.42263e2.css';
import '../../heading.1586cb4.css';
import '../../player_tables.304cf7d.css';
import '../../rest_player_tables.b6d027d.css';
import '../../ranking.07e6883.css';
import '../../common_category.24ec60d.css';
import '../../rssnews.f90b41b.css';
import '../../rssnews_scores.7834302.css';
import '../../standings_draw.24b004b.css';
import '../../recombox.8653960.css';
import '../../fsnews_scores.81b960c.css';
import '../../banner.57ada48.css';
import '../../commercial.43661cc.css';
import '../../soccer_template.18820c2.css';
import '../../sport_templates_layouts.57acbbb.css';
import '../../style.css';

const Portfolio = () => {


  return (
    <>
      <div>
        <section className="page2">
    <div id="box-over-content-a" className="boxOverContent--a isSticky isMobileSticky">

        <div id="box_over_content_12210" data-geoip="default" className="boxOverContent boxOverContent--type-2 isSticky isMobileSticky enabledSkeleton isNotClosed boxOverContent--active">

            <div id="bannerExpander_12210" className="boxOverContent__banner isExpanded">

                <img src="images/002526.png" width="108" height="auto" className="boxOverContent__image" alt="bookmaker" />

                <div className="boxOverContent__text">Join bet365 and get a bet credit bonus</div>

                <div className="boxOverContent__notice">Advertisement</div>


                <div className="boxOverContent__tac">see more at bet365.com for latest offers and details. Geo-variations and T&amp;Cs apply. 18+ gamblingtherapy.org</div>
                <div className="boxOverContent__arrow">
                    <svg className="boxOverContent__svg">
                    <use to="/tree"></use>
                </svg>
                </div>

                <Link className="boxOverContent__bannerLink" to="/tree" data-mobile-url="/promobox/12210/?sport=1&amp;mobile=1" > </Link>

            </div>

            <div className="boxOverContent__getOffer">
                <Link className="boxOverContent__getOfferLink" to="/tree" data-mobile-url="/promobox/12210/?sport=1&amp;mobile=1" >Claim your bonus!</Link>
            </div>

        </div>


    </div>
    <div id="tournament-table-tabs-and-content">
        <div className="filter tournamentTableDraw__filter">
            <div role="tablist" className="filter__group">
                <Link className="active" to="/tree" aria-current="page"><button role="tab" className="filter__filter filter__filter--selected">Standings</button> </Link>
                <Link className="" to="/tree"><button role="tab" className="filter__filter">Form</button> </Link><Link className="" to="/tree"><button role="tab" className="filter__filter">Over/Under</button> </Link>
                <Link className="" to="/tree"><button role="tab" className="filter__filter">HT/FT</button> </Link><Link className="" to="/tree"><button role="tab" className="filter__filter">Top Scorers</button> </Link>
            </div>
        </div>
        <div className="subFilter tournamentTableDraw__subFilter">
            <div role="tablist" className="subFilter__group">
                <Link className="active" to="/tree" aria-current="page"><button role="tab" className="subFilter__filter subFilter__filter--selected">Overall</button> </Link>
                <Link className="" to="/tree"><button role="tab" className="subFilter__filter">Home</button> </Link><Link className="" to="/tree"><button role="tab" className="subFilter__filter">Away</button> </Link>
            </div>
        </div>
        <div>
            <div>
                <div className="tableWrapper">
                    <div className="ui-table">
                        <div className="ui-table__header">
                            <div className="ui-table__headerCell table__headerCell--rank ui-table__headerCell--sortable ui-table__headerCell--sorted" role="button" tabindex="0" title="Rank">
                                #
                                <svg className="arrow-bold sortArrow-ico table__sortArrow">
                                <title></title>
                                <use ></use>
                            </svg>
                            </div>
                            <div className="ui-table__headerCell table__headerCell--participant ui-table__headerCell--sortable" role="button" tabindex="0" title="Team">Team</div>
                            <div className="ui-table__headerCell ui-table__headerCell--sortable" role="button" tabindex="0" title="Matches Played">MP</div>
                            <div className="ui-table__headerCell ui-table__headerCell--sortable" role="button" tabindex="0" title="Wins">W</div>
                            <div className="ui-table__headerCell ui-table__headerCell--sortable" role="button" tabindex="0" title="Draws">D</div>
                            <div className="ui-table__headerCell ui-table__headerCell--sortable" role="button" tabindex="0" title="Losses">L</div>
                            <div className="ui-table__headerCell table__cell--score ui-table__headerCell--sortable" role="button" tabindex="0" title="Goals">G</div>
                            <div className="ui-table__headerCell table__cell--goalsForAgainstDiff ui-table__headerCell--sortable" role="button" tabindex="0" title="Goal difference">GD</div>
                            <div className="ui-table__headerCell table__cell--points ui-table__headerCell--sortable" role="button" tabindex="0" title="Points">Pts</div>
                            <div className="ui-table__headerCell table__headerCell--form" role="button" tabindex="0" title="Last 5 matches">Form</div>
                        </div>
                        <div className="ui-table__body">
                            <div className="ui-table__row">
                                <div className="table__cell table__cell--rank table__cell--sorted">
                                    <div className="tableCellRank" title="Promotion - Champions League (Group Stage: )">1.</div>
                                </div>
                                <div className="table__cell table__cell--participant">
                                    <div className="tableCellParticipant">
                                        <div className="tableCellParticipant__block">
                                            <Link className="tableCellParticipant__image" to="/tree" title="West Ham">
                                            <img className="participant__image" loading="lazy" alt="West Ham" src="images/YeSfKGlC-ARW4wdCc.png" />
                                        </Link>
                                            <Link to="/tree"  className="tableCellParticipant__name">West Ham</Link>
                                        </div>
                                    </div>
                                </div>
                                <span className="table__cell table__cell--value">3</span><span className="table__cell table__cell--value">2</span><span className="table__cell table__cell--value">1</span><span className="table__cell table__cell--value">0</span>
                                <span className="table__cell table__cell--value table__cell--score">7:3</span><span className="table__cell table__cell--value table__cell--goalsForAgainstDiff">4</span>
                                <span className="table__cell table__cell--value table__cell--points">7</span>
                                <div className="table__cell table__cell--form">
                                    <div  className="tableCellFormIcon tableCellFormIcon--TBD">
                                        <div className="formIcon formIcon--tbd" title="">?</div>
                                    </div>
                                    <div  className="tableCellFormIcon">
                                        <div className="formIcon formIcon--w" title="">W</div>
                                    </div>
                                    <div  className="tableCellFormIcon">
                                        <div className="formIcon formIcon--w" title="">W</div>
                                    </div>
                                    <div  className="tableCellFormIcon">
                                        <div className="formIcon formIcon--d" title="">D</div>
                                    </div>
                                </div>
                            </div>
                            <div className="ui-table__row">
                                <div className="table__cell table__cell--rank table__cell--sorted">
                                    <div className="tableCellRank" title="Promotion - Champions League (Group Stage: )">2.</div>
                                </div>
                                <div className="table__cell table__cell--participant">
                                    <div className="tableCellParticipant">
                                        <div className="tableCellParticipant__block">
                                            <Link className="tableCellParticipant__image" to="/tree" title="Tottenham">
                                            <img className="participant__image" loading="lazy" alt="Tottenham" src="images/v3SzDxVH-SOY3p1Si.png" />
                                        </Link>
                                            <Link to="/tree"  className="tableCellParticipant__name">Tottenham</Link>
                                        </div>
                                    </div>
                                </div>
                                <span className="table__cell table__cell--value">3</span><span className="table__cell table__cell--value">2</span><span className="table__cell table__cell--value">1</span><span className="table__cell table__cell--value">0</span>
                                <span className="table__cell table__cell--value table__cell--score">6:2</span><span className="table__cell table__cell--value table__cell--goalsForAgainstDiff">4</span>
                                <span className="table__cell table__cell--value table__cell--points">7</span>
                                <div className="table__cell table__cell--form">
                                    <div  className="tableCellFormIcon tableCellFormIcon--TBD">
                                        <div className="formIcon formIcon--tbd" title="">?</div>
                                    </div>
                                    <div  className="tableCellFormIcon">
                                        <div className="formIcon formIcon--w" title="">W</div>
                                    </div>
                                    <div  className="tableCellFormIcon">
                                        <div className="formIcon formIcon--w" title="">W</div>
                                    </div>
                                    <div  className="tableCellFormIcon">
                                        <div className="formIcon formIcon--d" title="">D</div>
                                    </div>
                                </div>
                            </div>
                            <div className="ui-table__row">
                                <div className="table__cell table__cell--rank table__cell--sorted">
                                    <div className="tableCellRank" title="Promotion - Champions League (Group Stage: )">3.</div>
                                </div>
                                <div className="table__cell table__cell--participant">
                                    <div className="tableCellParticipant">
                                        <div className="tableCellParticipant__block">
                                            <Link className="tableCellParticipant__image" to="/tree" title="Arsenal">
                                            <img className="participant__image" loading="lazy" alt="Arsenal" src="images/0n1ffK6k-vcNAdtF9.png" />
                                        </Link>
                                            <Link to="/tree"  className="tableCellParticipant__name">Arsenal</Link>
                                        </div>
                                    </div>
                                </div>
                                <span className="table__cell table__cell--value">3</span><span className="table__cell table__cell--value">2</span><span className="table__cell table__cell--value">1</span><span className="table__cell table__cell--value">0</span>
                                <span className="table__cell table__cell--value table__cell--score">5:3</span><span className="table__cell table__cell--value table__cell--goalsForAgainstDiff">2</span>
                                <span className="table__cell table__cell--value table__cell--points">7</span>
                                <div className="table__cell table__cell--form">
                                    <div  className="tableCellFormIcon tableCellFormIcon--TBD">
                                        <div className="formIcon formIcon--tbd" title="">?</div>
                                    </div>
                                    <div  className="tableCellFormIcon">
                                        <div className="formIcon formIcon--d" title="">D</div>
                                    </div>
                                    <div  className="tableCellFormIcon">
                                        <div className="formIcon formIcon--w" title="">W</div>
                                    </div>
                                    <div  className="tableCellFormIcon">
                                        <div className="formIcon formIcon--w" title="">W</div>
                                    </div>
                                </div>
                            </div>
                            <div className="ui-table__row">
                                <div className="table__cell table__cell--rank table__cell--sorted">
                                    <div className="tableCellRank" title="Promotion - Champions League (Group Stage: )">4.</div>
                                </div>
                                <div className="table__cell table__cell--participant">
                                    <div className="tableCellParticipant">
                                        <div className="tableCellParticipant__block">
                                            <Link className="tableCellParticipant__image" to="/tree" title="Brighton">
                                            <img className="participant__image" loading="lazy" alt="Brighton" src="images/G0q9xjRq-GSg8EF2E.png" />
                                        </Link>
                                            <Link to="/tree"  className="tableCellParticipant__name">Brighton</Link>
                                        </div>
                                    </div>
                                </div>
                                <span className="table__cell table__cell--value">3</span><span className="table__cell table__cell--value">2</span><span className="table__cell table__cell--value">0</span><span className="table__cell table__cell--value">1</span>
                                <span className="table__cell table__cell--value table__cell--score">9:5</span><span className="table__cell table__cell--value table__cell--goalsForAgainstDiff">4</span>
                                <span className="table__cell table__cell--value table__cell--points">6</span>
                                <div className="table__cell table__cell--form">
                                    <div  className="tableCellFormIcon tableCellFormIcon--TBD">
                                        <div className="formIcon formIcon--tbd" title="">?</div>
                                    </div>
                                    <div  className="tableCellFormIcon">
                                        <div className="formIcon formIcon--l" title="">L</div>
                                    </div>
                                    <div  className="tableCellFormIcon">
                                        <div className="formIcon formIcon--w" title="">W</div>
                                    </div>
                                    <div  className="tableCellFormIcon">
                                        <div className="formIcon formIcon--w" title="">W</div>
                                    </div>
                                </div>
                            </div>
                            <div className="ui-table__row">
                                <div className="table__cell table__cell--rank table__cell--sorted">
                                    <div className="tableCellRank" title="Promotion - Europa League (Group Stage: )">5.</div>
                                </div>
                                <div className="table__cell table__cell--participant">
                                    <div className="tableCellParticipant">
                                        <div className="tableCellParticipant__block">
                                            <Link className="tableCellParticipant__image" to="/tree" title="Manchester City">
                                            <img className="participant__image" loading="lazy" alt="Manchester City" src="images/0vgscFU0-lQuhqN8N.png" />
                                        </Link>
                                            <Link to="/tree"  className="tableCellParticipant__name">Manchester City</Link>
                                        </div>
                                    </div>
                                </div>
                                <span className="table__cell table__cell--value">2</span><span className="table__cell table__cell--value">2</span><span className="table__cell table__cell--value">0</span><span className="table__cell table__cell--value">0</span>
                                <span className="table__cell table__cell--value table__cell--score">4:0</span><span className="table__cell table__cell--value table__cell--goalsForAgainstDiff">4</span>
                                <span className="table__cell table__cell--value table__cell--points">6</span>
                                <div className="table__cell table__cell--form">
                                    <div  className="tableCellFormIcon tableCellFormIcon--TBD">
                                        <div className="formIcon formIcon--tbd" title="">?</div>
                                    </div>
                                    <div  className="tableCellFormIcon">
                                        <div className="formIcon formIcon--w" title="">W</div>
                                    </div>
                                    <div  className="tableCellFormIcon">
                                        <div className="formIcon formIcon--w" title="">W</div>
                                    </div>
                                </div>
                            </div>
                            <div className="ui-table__row">
                                <div className="table__cell table__cell--rank table__cell--sorted">
                                    <div className="tableCellRank" title="">6.</div>
                                </div>
                                <div className="table__cell table__cell--participant">
                                    <div className="tableCellParticipant">
                                        <div className="tableCellParticipant__block">
                                            <Link className="tableCellParticipant__image" to="/tree" title="Manchester Utd">
                                            <img className="participant__image" loading="lazy" alt="Manchester Utd" src="images/GhGV3qjT-vcNAdtF9.png" />
                                        </Link>
                                            <Link to="/tree"  className="tableCellParticipant__name">Manchester Utd</Link>
                                        </div>
                                    </div>
                                </div>
                                <span className="table__cell table__cell--value">3</span><span className="table__cell table__cell--value">2</span><span className="table__cell table__cell--value">0</span><span className="table__cell table__cell--value">1</span>
                                <span className="table__cell table__cell--value table__cell--score">4:4</span><span className="table__cell table__cell--value table__cell--goalsForAgainstDiff">0</span>
                                <span className="table__cell table__cell--value table__cell--points">6</span>
                                <div className="table__cell table__cell--form">
                                    <div  className="tableCellFormIcon tableCellFormIcon--TBD">
                                        <div className="formIcon formIcon--tbd" title="">?</div>
                                    </div>
                                    <div className="tableCellFormIcon">
                                        <div className="formIcon formIcon--w" title="">W</div>
                                    </div>
                                    <div  className="tableCellFormIcon">
                                        <div className="formIcon formIcon--l" title="">L</div>
                                    </div>
                                    <div  className="tableCellFormIcon">
                                        <div className="formIcon formIcon--w" title="">W</div>
                                    </div>
                                </div>
                            </div>
                            <div className="ui-table__row">
                                <div className="table__cell table__cell--rank table__cell--sorted">
                                    <div className="tableCellRank" title="">7.</div>
                                </div>
                                <div className="table__cell table__cell--participant">
                                    <div className="tableCellParticipant">
                                        <div className="tableCellParticipant__block">
                                            <Link className="tableCellParticipant__image" to="/tree" title="Brentford">
                                            <img className="participant__image" loading="lazy" alt="Brentford" src="images/SjSCLv86-tCGtX12c.png" />
                                        </Link>
                                            <Link to="/tree"  className="tableCellParticipant__name">Brentford</Link>
                                        </div>
                                    </div>
                                </div>
                                <span className="table__cell table__cell--value">3</span><span className="table__cell table__cell--value">1</span><span className="table__cell table__cell--value">2</span><span className="table__cell table__cell--value">0</span>
                                <span className="table__cell table__cell--value table__cell--score">6:3</span><span className="table__cell table__cell--value table__cell--goalsForAgainstDiff">3</span>
                                <span className="table__cell table__cell--value table__cell--points">5</span>
                                <div className="table__cell table__cell--form">
                                    <div  className="tableCellFormIcon tableCellFormIcon--TBD">
                                        <div className="formIcon formIcon--tbd" title="">?</div>
                                    </div>
                                    <div  className="tableCellFormIcon">
                                        <div className="formIcon formIcon--d" title="">D</div>
                                    </div>
                                    <div  className="tableCellFormIcon">
                                        <div className="formIcon formIcon--w" title="">W</div>
                                    </div>
                                    <div  className="tableCellFormIcon">
                                        <div className="formIcon formIcon--d" title="">D</div>
                                    </div>
                                </div>
                            </div>
                            <div className="ui-table__row">
                                <div className="table__cell table__cell--rank table__cell--sorted">
                                    <div className="tableCellRank" title="">8.</div>
                                </div>
                                <div className="table__cell table__cell--participant">
                                    <div className="tableCellParticipant">
                                        <div className="tableCellParticipant__block">
                                            <Link className="tableCellParticipant__image" to="/tree" title="Liverpool">
                                            <img className="participant__image" loading="lazy" alt="Liverpool" src="images/x82JgBjC-vcNAdtF9.png" />
                                        </Link>
                                            <Link to="/tree"  className="tableCellParticipant__name">Liverpool</Link>
                                        </div>
                                    </div>
                                </div>
                                <span className="table__cell table__cell--value">2</span><span className="table__cell table__cell--value">1</span><span className="table__cell table__cell--value">1</span><span className="table__cell table__cell--value">0</span>
                                <span className="table__cell table__cell--value table__cell--score">4:2</span><span className="table__cell table__cell--value table__cell--goalsForAgainstDiff">2</span>
                                <span className="table__cell table__cell--value table__cell--points">4</span>
                                <div className="table__cell table__cell--form">
                                    <div  className="tableCellFormIcon tableCellFormIcon--TBD">
                                        <div className="formIcon formIcon--tbd" title="">?</div>
                                    </div>
                                    <div  className="tableCellFormIcon">
                                        <div className="formIcon formIcon--w" title="">W</div>
                                    </div>
                                    <div  className="tableCellFormIcon">
                                        <div className="formIcon formIcon--d" title="">D</div>
                                    </div>
                                </div>
                            </div>
                            <div className="ui-table__row">
                                <div className="table__cell table__cell--rank table__cell--sorted">
                                    <div className="tableCellRank" title="">9.</div>
                                </div>
                                <div className="table__cell table__cell--participant">
                                    <div className="tableCellParticipant">
                                        <div className="tableCellParticipant__block">
                                            <Link className="tableCellParticipant__image" to="/tree" title="Chelsea">
                                            <img className="participant__image" loading="lazy" alt="Chelsea" src="images/lMxEQ8me-vcNAdtF9.png" />
                                        </Link>
                                            <Link to="/tree"  className="tableCellParticipant__name">Chelsea</Link>
                                        </div>
                                    </div>
                                </div>
                                <span className="table__cell table__cell--value">3</span><span className="table__cell table__cell--value">1</span><span className="table__cell table__cell--value">1</span><span className="table__cell table__cell--value">1</span>
                                <span className="table__cell table__cell--value table__cell--score">5:4</span><span className="table__cell table__cell--value table__cell--goalsForAgainstDiff">1</span>
                                <span className="table__cell table__cell--value table__cell--points">4</span>
                                <div className="table__cell table__cell--form">
                                    <div  className="tableCellFormIcon tableCellFormIcon--TBD">
                                        <div className="formIcon formIcon--tbd" title="">?</div>
                                    </div>
                                    <div  className="tableCellFormIcon">
                                        <div className="formIcon formIcon--w" title="">W</div>
                                    </div>
                                    <div  className="tableCellFormIcon">
                                        <div className="formIcon formIcon--l" title="">L</div>
                                    </div>
                                    <div  className="tableCellFormIcon">
                                        <div className="formIcon formIcon--d" title="">D</div>
                                    </div>
                                </div>
                            </div>
                            <div className="ui-table__row">
                                <div className="table__cell table__cell--rank table__cell--sorted">
                                    <div className="tableCellRank" title="">10.</div>
                                </div>
                                <div className="table__cell table__cell--participant">
                                    <div className="tableCellParticipant">
                                        <div className="tableCellParticipant__block">
                                            <Link className="tableCellParticipant__image" to="/tree" title="Crystal Palace">
                                            <img className="participant__image" loading="lazy" alt="Crystal Palace" src="images/GfkvlLmC-pzMOQXRe.png" />
                                        </Link>
                                            <Link to="/tree"  className="tableCellParticipant__name">Crystal Palace</Link>
                                        </div>
                                    </div>
                                </div>
                                <span className="table__cell table__cell--value">3</span><span className="table__cell table__cell--value">1</span><span className="table__cell table__cell--value">1</span><span className="table__cell table__cell--value">1</span>
                                <span className="table__cell table__cell--value table__cell--score">2:2</span><span className="table__cell table__cell--value table__cell--goalsForAgainstDiff">0</span>
                                <span className="table__cell table__cell--value table__cell--points">4</span>
                                <div className="table__cell table__cell--form">
                                    <div  className="tableCellFormIcon tableCellFormIcon--TBD">
                                        <div className="formIcon formIcon--tbd" title="">?</div>
                                    </div>
                                    <div  className="tableCellFormIcon">
                                        <div className="formIcon formIcon--d" title="">D</div>
                                    </div>
                                    <div  className="tableCellFormIcon">
                                        <div className="formIcon formIcon--l" title="">L</div>
                                    </div>
                                    <div  className="tableCellFormIcon">
                                        <div className="formIcon formIcon--w" title="">W</div>
                                    </div>
                                </div>
                            </div>
                            <div className="ui-table__row">
                                <div className="table__cell table__cell--rank table__cell--sorted">
                                    <div className="tableCellRank" title="">11.</div>
                                </div>
                                <div className="table__cell table__cell--participant">
                                    <div className="tableCellParticipant">
                                        <div className="tableCellParticipant__block">
                                            <Link className="tableCellParticipant__image" to="/tree" title="Fulham">
                                            <img className="participant__image" loading="lazy" alt="Fulham" src="images/fkbYUWme-ImMEe0UF.png" />
                                        </Link>
                                            <Link to="/tree"  className="tableCellParticipant__name">Fulham</Link>
                                        </div>
                                    </div>
                                </div>
                                <span className="table__cell table__cell--value">3</span><span className="table__cell table__cell--value">1</span><span className="table__cell table__cell--value">1</span><span className="table__cell table__cell--value">1</span>
                                <span className="table__cell table__cell--value table__cell--score">3:5</span><span className="table__cell table__cell--value table__cell--goalsForAgainstDiff">-2</span>
                                <span className="table__cell table__cell--value table__cell--points">4</span>
                                <div className="table__cell table__cell--form">
                                    <div  className="tableCellFormIcon tableCellFormIcon--TBD">
                                        <div className="formIcon formIcon--tbd" title="">?</div>
                                    </div>
                                    <div  className="tableCellFormIcon">
                                        <div className="formIcon formIcon--d" title="">D</div>
                                    </div>
                                    <div  className="tableCellFormIcon">
                                        <div className="formIcon formIcon--l" title="">L</div>
                                    </div>
                                    <div  className="tableCellFormIcon">
                                        <div className="formIcon formIcon--w" title="">W</div>
                                    </div>
                                </div>
                            </div>
                            <div className="ui-table__row">
                                <div className="table__cell table__cell--rank table__cell--sorted">
                                    <div className="tableCellRank" title="">12.</div>
                                </div>
                                <div className="table__cell table__cell--participant">
                                    <div className="tableCellParticipant">
                                        <div className="tableCellParticipant__block">
                                            <Link className="tableCellParticipant__image" to="/tree" title="Newcastle">
                                            <img className="participant__image" loading="lazy" alt="Newcastle" src="images/UXo7VXPq-ImMEe0UF.png" />
                                        </Link>
                                            <Link to="/tree"  className="tableCellParticipant__name">Newcastle</Link>
                                        </div>
                                    </div>
                                </div>
                                <span className="table__cell table__cell--value">2</span><span className="table__cell table__cell--value">1</span><span className="table__cell table__cell--value">0</span><span className="table__cell table__cell--value">1</span>
                                <span className="table__cell table__cell--value table__cell--score">5:2</span><span className="table__cell table__cell--value table__cell--goalsForAgainstDiff">3</span>
                                <span className="table__cell table__cell--value table__cell--points">3</span>
                                <div className="table__cell table__cell--form">
                                    <div  className="tableCellFormIcon tableCellFormIcon--TBD">
                                        <div className="formIcon formIcon--tbd" title="">?</div>
                                    </div>
                                    <div  className="tableCellFormIcon">
                                        <div className="formIcon formIcon--l" title="">L</div>
                                    </div>
                                    <div  className="tableCellFormIcon">
                                        <div className="formIcon formIcon--w" title="">W</div>
                                    </div>
                                </div>
                            </div>
                            <div className="ui-table__row table__row--selected">
                                <div className="table__cell table__cell--rank table__cell--sorted">
                                    <div className="tableCellRank" title="">13.</div>
                                </div>
                                <div className="table__cell table__cell--participant">
                                    <div className="tableCellParticipant">
                                        <div className="tableCellParticipant__block">
                                            <Link className="tableCellParticipant__image" to="/tree" title="Aston Villa">
                                            <img className="participant__image" loading="lazy" alt="Aston Villa" src="images/UHchCEVH-dC4qHhKr.png" />
                                        </Link>
                                            <Link to="/tree"  className="tableCellParticipant__name">Aston Villa</Link>
                                        </div>
                                    </div>
                                </div>
                                <span className="table__cell table__cell--value">2</span><span className="table__cell table__cell--value">1</span><span className="table__cell table__cell--value">0</span><span className="table__cell table__cell--value">1</span>
                                <span className="table__cell table__cell--value table__cell--score">5:5</span><span className="table__cell table__cell--value table__cell--goalsForAgainstDiff">0</span>
                                <span className="table__cell table__cell--value table__cell--points">3</span>
                                <div className="table__cell table__cell--form">
                                    <div  className="tableCellFormIcon tableCellFormIcon--TBD">
                                        <div className="formIcon formIcon--tbd" title="">?</div>
                                    </div>
                                    <div  className="tableCellFormIcon">
                                        <div className="formIcon formIcon--w" title="">W</div>
                                    </div>
                                    <div  className="tableCellFormIcon">
                                        <div className="formIcon formIcon--l" title="">L</div>
                                    </div>
                                </div>
                            </div>
                            <div className="ui-table__row">
                                <div className="table__cell table__cell--rank table__cell--sorted">
                                    <div className="tableCellRank" title="">14.</div>
                                </div>
                                <div className="table__cell table__cell--participant">
                                    <div className="tableCellParticipant">
                                        <div className="tableCellParticipant__block">
                                            <Link className="tableCellParticipant__image" to="/tree" title="Nottingham">
                                            <img className="participant__image" loading="lazy" alt="Nottingham" src="images/86ODdyle-46TmZhMa.png" />
                                        </Link>
                                            <Link to="/tree"  className="tableCellParticipant__name">Nottingham</Link>
                                        </div>
                                    </div>
                                </div>
                                <span className="table__cell table__cell--value">3</span><span className="table__cell table__cell--value">1</span><span className="table__cell table__cell--value">0</span><span className="table__cell table__cell--value">2</span>
                                <span className="table__cell table__cell--value table__cell--score">5:6</span><span className="table__cell table__cell--value table__cell--goalsForAgainstDiff">-1</span>
                                <span className="table__cell table__cell--value table__cell--points">3</span>
                                <div className="table__cell table__cell--form">
                                    <div  className="tableCellFormIcon tableCellFormIcon--TBD">
                                        <div className="formIcon formIcon--tbd" title="">?</div>
                                    </div>
                                    <div className="tableCellFormIcon">
                                        <div className="formIcon formIcon--l" title="">L</div>
                                    </div>
                                    <div  className="tableCellFormIcon">
                                        <div className="formIcon formIcon--w" title="">W</div>
                                    </div>
                                    <div  className="tableCellFormIcon">
                                        <div className="formIcon formIcon--l" title="">L</div>
                                    </div>
                                </div>
                            </div>
                            <div className="ui-table__row">
                                <div className="table__cell table__cell--rank table__cell--sorted">
                                    <div className="tableCellRank" title="">15.</div>
                                </div>
                                <div className="table__cell table__cell--participant">
                                    <div className="tableCellParticipant">
                                        <div className="tableCellParticipant__block">
                                            <Link className="tableCellParticipant__image" to="/tree" title="Wolves">
                                            <img className="participant__image" loading="lazy" alt="Wolves" src="images/OMUzjDkC-CjV6Eptm.png" />
                                        </Link>
                                            <Link to="/tree"  className="tableCellParticipant__name">Wolves</Link>
                                        </div>
                                    </div>
                                </div>
                                <span className="table__cell table__cell--value">3</span><span className="table__cell table__cell--value">1</span><span className="table__cell table__cell--value">0</span><span className="table__cell table__cell--value">2</span>
                                <span className="table__cell table__cell--value table__cell--score">2:5</span><span className="table__cell table__cell--value table__cell--goalsForAgainstDiff">-3</span>
                                <span className="table__cell table__cell--value table__cell--points">3</span>
                                <div className="table__cell table__cell--form">
                                    <div  className="tableCellFormIcon tableCellFormIcon--TBD">
                                        <div className="formIcon formIcon--tbd" title="">?</div>
                                    </div>
                                    <div  className="tableCellFormIcon">
                                        <div className="formIcon formIcon--w" title="">W</div>
                                    </div>
                                    <div  className="tableCellFormIcon">
                                        <div className="formIcon formIcon--l" title="">L</div>
                                    </div>
                                    <div  className="tableCellFormIcon">
                                        <div className="formIcon formIcon--l" title="">L</div>
                                    </div>
                                </div>
                            </div>
                            <div className="ui-table__row">
                                <div className="table__cell table__cell--rank table__cell--sorted">
                                    <div className="tableCellRank" title="">16.</div>
                                </div>
                                <div className="table__cell table__cell--participant">
                                    <div className="tableCellParticipant">
                                        <div className="tableCellParticipant__block">
                                            <Link className="tableCellParticipant__image" to="/tree" title="Bournemouth">
                                            <img className="participant__image" loading="lazy" alt="Bournemouth" src="images/C60HMWTH-tCGtX12c.png" />
                                        </Link>
                                            <Link to="/tree"  className="tableCellParticipant__name">Bournemouth</Link>
                                        </div>
                                    </div>
                                </div>
                                <span className="table__cell table__cell--value">3</span><span className="table__cell table__cell--value">0</span><span className="table__cell table__cell--value">1</span><span className="table__cell table__cell--value">2</span>
                                <span className="table__cell table__cell--value table__cell--score">2:6</span><span className="table__cell table__cell--value table__cell--goalsForAgainstDiff">-4</span>
                                <span className="table__cell table__cell--value table__cell--points">1</span>
                                <div className="table__cell table__cell--form">
                                    <div  className="tableCellFormIcon tableCellFormIcon--TBD">
                                        <div className="formIcon formIcon--tbd" title="">?</div>
                                    </div>
                                    <div  className="tableCellFormIcon">
                                        <div className="formIcon formIcon--l" title="">L</div>
                                    </div>
                                    <div  className="tableCellFormIcon">
                                        <div className="formIcon formIcon--l" title="">L</div>
                                    </div>
                                    <div  className="tableCellFormIcon">
                                        <div className="formIcon formIcon--d" title="">D</div>
                                    </div>
                                </div>
                            </div>
                            <div className="ui-table__row">
                                <div className="table__cell table__cell--rank table__cell--sorted">
                                    <div className="tableCellRank" title="">17.</div>
                                </div>
                                <div className="table__cell table__cell--participant">
                                    <div className="tableCellParticipant">
                                        <div className="tableCellParticipant__block">
                                            <Link className="tableCellParticipant__image" to="/tree" title="Sheffield Utd">
                                            <img className="participant__image" loading="lazy" alt="Sheffield Utd" src="images/8nXNvlA6-bgElVuX9.png" />
                                        </Link>
                                            <Link to="/tree"  className="tableCellParticipant__name">Sheffield Utd</Link>
                                        </div>
                                    </div>
                                </div>
                                <span className="table__cell table__cell--value">2</span><span className="table__cell table__cell--value">0</span><span className="table__cell table__cell--value">0</span><span className="table__cell table__cell--value">2</span>
                                <span className="table__cell table__cell--value table__cell--score">1:3</span><span className="table__cell table__cell--value table__cell--goalsForAgainstDiff">-2</span>
                                <span className="table__cell table__cell--value table__cell--points">0</span>
                                <div className="table__cell table__cell--form">
                                    <div  className="tableCellFormIcon tableCellFormIcon--TBD">
                                        <div className="formIcon formIcon--tbd" title="">?</div>
                                    </div>
                                    <div  className="tableCellFormIcon">
                                        <div className="formIcon formIcon--l" title="">L</div>
                                    </div>
                                    <div  className="tableCellFormIcon">
                                        <div className="formIcon formIcon--l" title="">L</div>
                                    </div>
                                </div>
                            </div>
                            <div className="ui-table__row table__row--selected">
                                <div className="table__cell table__cell--rank table__cell--sorted">
                                    <div className="tableCellRank" title="Relegation - Championship">18.</div>
                                </div>
                                <div className="table__cell table__cell--participant">
                                    <div className="tableCellParticipant">
                                        <div className="tableCellParticipant__block">
                                            <Link className="tableCellParticipant__image" to="/tree" title="Burnley">
                                            <img className="participant__image" loading="lazy" alt="Burnley" src="images/xO3nES96-6PhTI7J6.png" />
                                        </Link>
                                            <Link to="/tree"  className="tableCellParticipant__name">Burnley</Link>
                                        </div>
                                    </div>
                                </div>
                                <span className="table__cell table__cell--value">1</span><span className="table__cell table__cell--value">0</span><span className="table__cell table__cell--value">0</span><span className="table__cell table__cell--value">1</span>
                                <span className="table__cell table__cell--value table__cell--score">0:3</span><span className="table__cell table__cell--value table__cell--goalsForAgainstDiff">-3</span>
                                <span className="table__cell table__cell--value table__cell--points">0</span>
                                <div className="table__cell table__cell--form">
                                    <div  className="tableCellFormIcon tableCellFormIcon--TBD">
                                        <div className="formIcon formIcon--tbd" title="">?</div>
                                    </div>
                                    <div  className="tableCellFormIcon">
                                        <div className="formIcon formIcon--l" title="">L</div>
                                    </div>
                                </div>
                            </div>
                            <div className="ui-table__row">
                                <div className="table__cell table__cell--rank table__cell--sorted">
                                    <div className="tableCellRank" title="Relegation - Championship">19.</div>
                                </div>
                                <div className="table__cell table__cell--participant">
                                    <div className="tableCellParticipant">
                                        <div className="tableCellParticipant__block">
                                            <Link className="tableCellParticipant__image" to="/tree" title="Luton">
                                            <img className="participant__image" loading="lazy" alt="Luton" src="images/GQSSQahT-dATKxRKP.png" />
                                        </Link>
                                            <Link to="/tree"  className="tableCellParticipant__name">Luton</Link>
                                        </div>
                                    </div>
                                </div>
                                <span className="table__cell table__cell--value">2</span><span className="table__cell table__cell--value">0</span><span className="table__cell table__cell--value">0</span><span className="table__cell table__cell--value">2</span>
                                <span className="table__cell table__cell--value table__cell--score">1:7</span><span className="table__cell table__cell--value table__cell--goalsForAgainstDiff">-6</span>
                                <span className="table__cell table__cell--value table__cell--points">0</span>
                                <div className="table__cell table__cell--form">
                                    <div  className="tableCellFormIcon tableCellFormIcon--TBD">
                                        <div className="formIcon formIcon--tbd" title="">?</div>
                                    </div>
                                    <div  className="tableCellFormIcon">
                                        <div className="formIcon formIcon--l" title="">L</div>
                                    </div>
                                    <div  className="tableCellFormIcon">
                                        <div className="formIcon formIcon--l" title="">L</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>  
    </div>
    </>
  );
};

export default Portfolio;
