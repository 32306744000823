import React from "react";
import '../../dropdown.687117f.css';
import '../../1componentLibraryTheme2021.aeafbeb.css';
import '../../detail_section_form.86751c8.css';
import '../../detail_sections.d2636fb.css';
import '../../1banner.57ada48.css';
import '../../LivesportFinderLatin.06df268.css';
import '../../core.18d0bbe.css';
import '../../variables.1611a44.css';
import '../../themes.17e5560.css';
import '../../common.ad425e7.css';
import '../../components_shared.28090ff.css';
import '../../cookie.0635bcc.css';
import '../../multiLang.01f626d.css';
import '../../core_common.7c5dcf1.css';
import '../../lsid.0ffe621.css'; 
import '../../dropdown.687117f.css';
import '../../componentLibraryTheme2021.aeafbeb.css';
import '../../live_header.067a8ee.css';
import '../../live_sidemenu.4e809a9.css';
import '../../live_sections.5c86d21.css';
import '../../wizard.561951a.css';
import '../../league_onboarding.b59db54.css';
import '../../live_footer.26a4abe.css';
import '../../tabs_filters.0656451.css';
import '../../live_tabs.be1971d.css';
import '../../headline.42263e2.css';
import '../../heading.1586cb4.css';
import '../../player_tables.304cf7d.css';
import '../../rest_player_tables.b6d027d.css';
import '../../ranking.07e6883.css';
import '../../common_category.24ec60d.css';
import '../../rssnews.f90b41b.css';
import '../../rssnews_scores.7834302.css';
import '../../standings_draw.24b004b.css';
import '../../recombox.8653960.css';
import '../../fsnews_scores.81b960c.css';
import '../../banner.57ada48.css';
import '../../commercial.43661cc.css';
import '../../soccer_template.18820c2.css';
import '../../sport_templates_layouts.57acbbb.css';
import '../../style.css';

const Contact = ({ name, email, location }) => {
  return (
    <>
      <div>
        <section className="page3">
            <ul className="topHeading">
                <li>
                    1/32 De Final
                </li>
                <li>
                    1/16 De Final
                </li>
                <li>
                    1/8 De Final
                </li>
            </ul>
            <div className="tree">
                <ul>
                    <li>
                        <div className="mainList">
                         <div className="inner">
                            <div className="list">
                                <div className="group first">
                                    <div className="item">
                                        <label><img src="images/spain.png" alt="" />Alcaraz C. (1) <span></span></label>
                                    </div>
                                    <div className="item">
                                        <label><img src="images/usa.png" alt="" />Isner J. (WC) <span>0</span></label>
                                        <label className="bold"><img src="images/australia.png" alt="" />Thompson J. (Q) <span>2</span></label>
                                    </div>
                                </div>
                                <div className="group second">
                                    <div className="item">
                                        <label className="bold"><img src="images/spain.png" alt="" />Alcaraz C. (1) <span>2</span></label>
                                        <label><img src="images/usa.png" alt="" />Thompson J. (Q) <span>1</span></label>
                                    </div>
                                </div>
                            </div>
                            <div className="list">
                                <div className="group first">
                                    <div className="item">
                                        <label className="bold"><img src="images/france.png" alt="" />Humbert U. <span>2</span></label>
                                        <label><img src="images/france.png" alt="" />Fils A. (Q) <span>1</span></label>
                                    </div>
                                    <div className="item">
                                        <label><img src="images/kecmanovic.png" alt="" />Kecmanovic M. <span>0</span></label>
                                        <label className="bold"><img src="images/usa.png" alt="" />Paul T. (14) <span>2</span></label>
                                    </div>
                                </div>
                                <div className="group second">
                                    <div className="item">
                                        <label><img src="images/france.png" alt="" />Humbert U. <span>0</span></label>
                                        <label className="bold"><img src="images/usa.png" alt="" />Paul T. (14) <span>2</span></label>
                                    </div>
                                </div>
                            </div>
                          </div>
                            <div className="final">
                                <div className="item">
                                    <label><img src="images/spain.png" alt="" />Alcaraz C. (1) <span></span></label>
                                    <label className="bold"><img src="images/usa.png" alt="" />Paul T. (14) <span></span></label>
                                </div>
                            </div>
                        </div>
                        <div className="mainList">
                          <div className="inner">
                            <div className="list">
                                <div className="group first">
                                    <div className="item">
                                        <label className="bold"><img src="images/usa.png" alt="" />Tiafoe F. (10) <span>2</span></label>
                                        <label><img src="images/france.png" alt="" />Griekspoor T. <span>1</span></label>
                                    </div>
                                    <div className="item">
                                        <label><img src="images/usa.png" alt="" />Nakashima B. (WC) <span>1</span></label>
                                        <label className="bold"><img src="images/switzerland.png" alt="" />Wawrinka S. <span>2</span></label>
                                    </div>
                                </div>
                                <div className="group second">
                                    <div className="item">
                                        <label><img src="images/usa.png" alt="" />Tiafoe F. (10) <span>0</span></label>
                                        <label className="bold"><img src="images/switzerland.png" alt="" />Wawrinka S. <span>2</span></label>
                                    </div>
                                </div>
                            </div>
                            <div className="list">
                                <div className="group first">
                                    <div className="item">
                                        <label><img src="images/southafirica.png" alt="" />Harris L. (PR) <span>0</span></label>
                                        <label className="bold"><img src="images/australia.png" alt="" />Purcell M. (Q) <span>2</span></label>
                                    </div>
                                    <div className="item">
                                        <label><img src="images/ruud.png" alt="" />Ruud C. (5) <span>1</span></label>
                                    </div>
                                </div>
                                <div className="group second">
                                    <div className="item">
                                        <label className="bold"><img src="images/australia.png" alt="" />Purcell M. (Q) <span>2</span></label>
                                        <label><img src="images/ruud.png" alt="" />Ruud C. (5) <span>1</span></label>
                                    </div>
                                </div>
                            </div>
                          </div>
                            <div className="final">
                                <div className="item">
                                    <label><img src="images/switzerland.png" alt="" />Wawrinka S. <span></span></label>
                                    <label className="bold"><img src="images/australia.png" alt="" />Purcell M. <span></span></label>
                                </div>
                            </div>
                        </div>
                        <div className="mainList">
                          <div className="inner last">
                            <div className="list">
                                <div className="group first">
                                    <div className="item">
                                        <label><img src="images/greece.png" alt="" />Tsitsipas S. (4) <span></span></label>
                                    </div>
                                    <div className="item">
                                        <label className="bold"><img src="images/usa.png" alt="" />Shelton B. <span>2</span></label>
                                        <label><img src="images/usa.png" alt="" />Eubanks C <span>1</span></label>
                                    </div>
                                </div>
                                <div className="group second">
                                    <div className="item">
                                        <label className="bold"><img src="images/greece.png" alt="" />Tsitsipas S. (4) <span>2</span></label>
                                        <label><img src="images/usa.png" alt="" />Shelton B. <span>0</span></label>
                                    </div>
                                </div>
                            </div>
                          </div>
                        </div>
                    </li>
                </ul>
            </div>
        </section>    
    </div>
    </>
  );
};

export default Contact;
